import request from '@/plugins/axios'

/** 代理用户 **/
// 代理用户列表
export const apiProxyUserList = (params: any) => request.get('/shop.proxy_user/getList', {params})

// 获取代理详情
export const apiProxyUserDetail = (params: any) => request.get('/shop.proxy_user/detail', {params})

// 添加代理用户
export const apiProxyUserAdd = (params: any) => request.post('/shop.proxy_user/add', params)

// 编辑代理用户
export const apiProxyUserEdit = (params: any) => request.post('/shop.proxy_user/update', params)

// 删除代理用户
export const apiProxyUserDelete = (params: any) => request.post('/shop.proxy_user/delete', params)
