
import { Component, Vue, Watch } from "vue-property-decorator";
// import { apiShopDetail, apiShopChangeSuperAdmin } from "@/api/shop";
import { apiProxyUserDetail, apiProxyUserAdd, apiProxyUserEdit } from "@/api/user";
@Component
export default class UpdateAccount extends Vue {
    /** S Data **/

    identity: number | null = null; // 当前编辑用户的身份ID

    // 添加团购表单数据
    form: any = {
        id: '', // id
        proxy_type: 1, // 代理类型
        name: "", // 名称
        account: "", // 账号
        password: "", // 密码
        expire_time: "", // 到期时间
        create_shop_num: 0, // 可创建团购数量
        // super_password_confirm: "", // 确认密码
        contact_name: '', // 联系人姓名
        contact_mobile: '', // 联系人电话
        enterprise_name: '', // 企业名称
        enterprise_address: '', // 企业地址
    };

    // 表单校验
    rules = {
        name: [
            { required: true, message: "请输入名称", trigger: "blur" },
        ],
        account: [
            { required: true, message: "请输入账号", trigger: "blur" },
        ],
        expire_time: [
            { required: true, message: "请选择到期时间", trigger: ['blur', 'change'] },
        ],
        create_shop_num: [
            { required: true, message: "请输入可创建团购数量", trigger: ['blur', 'change'] },
        ],
        contact_mobile: [
            { required: true, message: "请输入联系人电话", trigger: ['blur', 'change'] },
        ],
        contact_name: [
            { required: true, message: "请输入联系人姓名", trigger: ['blur', 'change'] },
        ],
        proxy_type: [
            { required: true, message: "请选择代理类型", trigger: ['blur', 'change'] },
        ],
        // password: [
        //     { required: true, message: "请输入密码", trigger: "blur" },
        // ],
        // super_password_confirm: [
        //     { required: true, message: "请再次输入密码", trigger: "blur" },
        //     {
        //         validator: (rule: object, value: string, callback: any) => {
        //             if (this.form.password) {
        //                 if (!value) callback(new Error("请再次输入密码"));
        //                 if (value !== this.form.password)
        //                     callback(new Error("两次输入密码不一致!"));
        //             }
        //             callback();
        //         },
        //         trigger: "blur",
        //     },
        // ],
    };

    /** E Data **/

    /** S Methods **/
    // 点击表单提交
    onSubmit(formName: string) {
        // 验证表单格式是否正确
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): any => {
            if (!valid) return;
            this.handleEdit();
        });
    }

    // 编辑超级管理员账号
    async handleEdit(): Promise<void> {
        console.log(this.identity !== null && this.identity !== Number.NaN)
        if (this.identity) {
            await apiProxyUserEdit({ ...this.form, id: this.identity });
            setTimeout(() => this.$router.go(-1), 500);
        } else {
            await apiProxyUserAdd({ ...this.form});
            setTimeout(() => this.$router.go(-1), 500);
        }
    }

    // 获取详情
    async getAdminDetailFunc(): Promise<void> {
        const res: any = await apiProxyUserDetail({
            id: this.identity as number
        })
        this.form = res;
        console.log(this.form);
        // this.form.password = "";
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query;

        // 编辑模式：初始化数据
        this.identity = +query.id;
        if (+query.id) {
            this.getAdminDetailFunc();
        }
    }
    /** E Life Cycle **/
}
